<template>
  <ConfigProvider :locale="ru_RU">
    <Layout>
      <LayoutHeader style="background: #FBFBFB">
        <Row type="flex" justify="space-between" align="middle">
          <img src="/img/logo-full.png" width="879" height="335" style="height: 40px; width: auto">
          <div>
            Выпуск сертификатов
          </div>
          <div>
            {{user.name}}&nbsp;&nbsp;&nbsp;
            <Button type="primary" danger @click="logout">
              <template #icon>
                <PoweroffOutlined />
              </template>
            </Button>
          </div>
        </Row>
      </LayoutHeader>
      <LayoutContent style="background:#fff; padding: 50px">

        <Menu v-model:selectedKeys="selectedKeys" mode="horizontal">
          <MenuItem key="/">
            <router-link :to="{name: 'Home'}">Создание сертификата</router-link>
          </MenuItem>
          <MenuItem key="/archive">
            <router-link :to="{name: 'Archive'}">Архив</router-link>
          </MenuItem>
        </Menu><br>

        <slot/>
      </LayoutContent>
    </Layout>
  </ConfigProvider>
</template>

<script setup>
import ru_RU from 'ant-design-vue/lib/locale-provider/ru_RU';
import {
  ConfigProvider,
  Layout, LayoutHeader, LayoutContent,
  Button,
  Row,
  Menu, MenuItem
} from "ant-design-vue";
import {
  PoweroffOutlined
} from '@ant-design/icons-vue';
import {ref, reactive, provide} from "vue";

const user = reactive({
  auth: false,
  avatar: null,
  id: null,
  name: null,
  email: null,
})
provide('$user', user)

const version = $version
const loading = ref(true)
const loadUser = async () => await $axios.get('auth').then(res => {
  user.auth = true
  user.id = res.data.id
  user.name = res.data.name
  user.email = res.data.email
  loading.value = false
}).catch(() => {
  logout()
})
if (!user.auth) loadUser()

const logout = () => {
  $cookie.removeCookie($auth_name, {path: '/'})
  window.location.href = '/login'
}

import {useRoute} from 'vue-router';
const route = useRoute()
let selectedKeys = ref([route.path])

</script>

<style lang="less" scoped>

</style>
