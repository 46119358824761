<template>
  <Card style="background: #fdfdfd; box-shadow: 0 5px 15px rgba(0,0,0,0.1); border-radius: 8px">
    <Form ref="formRef" :model="formState" :rules="rules" @finish="submit" layout="vertical">
      <!--      <Row :gutter="8">-->
      <!--        <Col :xs="12">-->
      <!--          <FormItem name="salon" label="Салон">-->
      <!--            <Select v-model:value="formState.salon" placeholder="-" size="large">-->
      <!--              <SelectOption value="auto1">{{ $user.name }}</SelectOption>-->
      <!--            </Select>-->
      <!--          </FormItem>-->
      <!--        </Col>-->
      <!--      </Row>-->

      <Row :gutter="8">
        <Col :xs="12">
          <FormItem name="name" label="Ф.И.О. клиента">
            <Input v-model:value="formState.name" placeholder="" size="large"/>
          </FormItem>
        </Col>
        <Col :xs="6">
          <FormItem name="birth" label="Дата рождения">
            <Input v-model:value="formState.birth" v-maska="'##.##.####'" placeholder="ДД.ММ.ГГГГ" size="large"/>
          </FormItem>
        </Col>
        <Col :xs="6">
          <FormItem name="phone" label="Номер телефона">
            <Input v-model:value="formState.phone" v-maska="'+7 (###) ###-##-##'" placeholder="+7 (999) 888-77-66"
                   size="large"/>
          </FormItem>
        </Col>
      </Row>

      <Row :gutter="8">
        <Col :xs="6">
          <FormItem name="email" label="E-mail">
            <Input v-model:value="formState.email" placeholder="email@mail.ru" size="large"/>
          </FormItem>
        </Col>
        <Col :xs="18">
          <FormItem name="address" label="Адрес регистрации или проживания">
            <Input v-model:value="formState.address" placeholder="" size="large"/>
          </FormItem>
        </Col>
      </Row>

      <Row :gutter="8">
        <Col :xs="4">
          <FormItem name="date" label="Дата сертификата">
            <Input v-model:value="formState.date" v-maska="'##.##.####'" placeholder="ДД.ММ.ГГГГ" size="large"/>
          </FormItem>
        </Col>
        <Col :xs="5">
          <FormItem name="price" label="Стоимость">
            <template v-if="$user.name && ($user.name).indexOf('(Legacy Light)') === -1">
              <Input v-model:value="formState.price"
                     v-maska="['###','# ###', '## ###', '### ####', '# ### ###', '## ### ###', '### ### ###']"
                     placeholder="" size="large"
                     @blur="customError.price = ''"/>
              <Alert style="margin-top: 5px" type="error" v-if="customError.price" :message="customError.price" banner/>
            </template>
            <Select v-else v-model:value="formState.price" placeholder="-" size="large" @change="">
              <SelectOption value="1 500">1 500</SelectOption>
              <SelectOption value="2 000">2 000</SelectOption>
              <SelectOption value="2 500">2 500</SelectOption>
              <SelectOption value="3 000">3 000</SelectOption>
              <SelectOption value="3 500">3 500</SelectOption>
              <SelectOption value="4 000">4 000</SelectOption>
              <SelectOption value="4 500">4 500</SelectOption>
              <SelectOption value="5 000">5 000</SelectOption>
              <SelectOption value="5 500">5 500</SelectOption>
              <SelectOption value="6 000">6 000</SelectOption>
              <SelectOption value="6 500">6 500</SelectOption>
            </Select>
          </FormItem>
        </Col>
        <Col :xs="4">
          <FormItem name="start" label="Начало действия">
            <Input v-model:value="formState.start" v-maska="'##.##.####'" placeholder="ДД.ММ.ГГГГ" size="large"/>
          </FormItem>
        </Col>
        <Col :xs="4"
             v-if="$user.name && (($user.name).indexOf('Автостиль') > -1 || ($user.name).indexOf('СамАвто') > -1 || ($user.name).indexOf('Авангард') > -1 || ($user.name).indexOf('Шувалово') > -1 || ($user.name).indexOf('Гусар') > -1 || ($user.name).indexOf('(Legacy Light)') > -1)">
          <FormItem name="step">
            <template #label>
              Срок
              <template v-if="formState.end">(до {{ formState.end }})</template>
            </template>
            <Select v-model:value="formState.step" placeholder="-" size="large" @change="">
              <template v-for="i in month">
                <SelectOption :value="i+''">{{ i }} мес.</SelectOption>
              </template>
            </Select>
          </FormItem>
        </Col>
        <Col :xs="4" v-else>
          <FormItem name="start" label="Срок">
            <Input v-model:value="formState.end" v-maska="'##.##.####'" placeholder="ДД.ММ.ГГГГ" size="large"/>
          </FormItem>
        </Col>
        <Col :xs="7">
          <FormItem name="vin" label="VIN">
            <Input v-model:value="formState.vin" placeholder="" size="large"/>
          </FormItem>
        </Col>
        <Col :xs="24"
             v-if="$user.name && (($user.name).indexOf('СамАвто') > -1 || ($user.name).indexOf('Авангард') > -1 || ($user.name).indexOf('Шувалово') > -1 || ($user.name).indexOf('Автостиль') > -1)">
          <FormItem label="">
            <Checkbox v-model:checked="formState.tech">Сертификат тех. помощи</Checkbox>
          </FormItem>
        </Col>
      </Row>

      <Space>
        <Button type="primary" :loading="loading" html-type="submit" size="large">Сгенерировать сертификат</Button>
        <Button danger html-type="reset" size="large">Сбросить данные</Button>
        <a v-if="$user.name && ($user.name).indexOf('Автостиль') > -1" :href="`/offer_autostil.pdf?t=${Date.now()}`"
           target="_blank">
          <Button size="large">Оферта</Button>
        </a>
        <a v-else-if="$user.name && ($user.name).indexOf('(Legacy Light)') === -1" :href="`/offer.pdf?t=${Date.now()}`"
           target="_blank">
          <Button size="large">Оферта</Button>
        </a>
        <a v-if="$user.name && ($user.name).indexOf('Шувалово') > -1" :href="`/presa.pdf?t=${Date.now()}`"
           target="_blank">
          <Button size="large">Презентация</Button>
        </a>
        <a v-if="$user.name && ($user.name).indexOf('(Legacy Light)') > -1" :href="`/presa_light.pdf?t=${Date.now()}`"
           target="_blank">
          <Button size="large">Презентация</Button>
        </a>
      </Space>
    </Form>
  </Card>
</template>

<script setup>
import {
    Alert,
    Button,
    Card,
    Checkbox,
    Col,
    Form,
    FormItem,
    Input,
    Row,
    Select,
    SelectOption,
    Space
} from "ant-design-vue";
import {computed, inject, reactive, ref, toRaw, watch} from "vue";
import router from "@/router";
import moment from "moment"

const $user = inject('$user')

const formRef = ref();
const formState = reactive({
  salon: 'auto1',
  name: '',
  birth: '',
  phone: '',
  email: '',
  address: '',
  date: moment().format('DD.MM.YYYY'),
  price: '',
  start: moment().format('DD.MM.YYYY'),
  step: '12',
  end: '',
  vin: ''
});
const rules = {
  salon: [
    {
      required: true,
      message: 'Выберите салон',
      trigger: 'blur',
    },
  ],
  name: [
    {
      required: true,
      message: 'Введите данные',
      trigger: 'blur',
    },
  ],
  birth: [
    {
      required: true,
      message: 'Введите данные',
      trigger: 'blur',
    },
  ],
  phone: [
    {
      required: true,
      message: 'Введите данные',
      trigger: 'blur',
    },
  ],
  email: [
    {
      required: true,
      message: 'Не может быть пустым',
      trigger: 'blur',
    },
    {
      type: 'email',
      message: 'Не верный формат адреса',
      trigger: 'blur',
    },
  ],
  address: [
    {
      required: true,
      message: 'Введите данные',
      trigger: 'blur',
    },
  ],
  date: [
    {
      required: true,
      message: 'Введите данные',
      trigger: 'blur',
    },
  ],
  start: [
    {
      required: true,
      message: 'Введите данные',
      trigger: 'blur',
    },
  ],
  step: [
    {
      required: true,
      message: 'Введите данные',
      trigger: 'blur',
    },
  ],
  price: [
    {
      required: true,
      message: 'Введите данные',
      trigger: 'blur',
    },
  ],
  // vin: [
  //   {
  //     required: true,
  //     message: 'Введите данные',
  //     trigger: 'blur',
  //   },
  // ],
};
const customError = reactive({})

watch(() => $user.name, (val) => {
  if (val.indexOf('Автостиль') > -1 ||
      val.indexOf('СамАвто') > -1 ||
      val.indexOf('Шувалово') > -1 ||
      val.indexOf('Авангард') > -1) {
    formState.price = '20 000'
    calc_end()
  }

  if (val.indexOf('Гусар') > -1) {
    formState.price = '3 500'
    calc_end()
  }
})

const month = computed(() => {
  let price = parseInt(formState.price.replace(' ', ''))

  if ($user.name && $user.name.indexOf('Автостиль') > -1) {
    if (price < 30000) {
      let month = [
        12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23,
        24, 25, 26, 27, 28, 29, 30, 31, 32, 33, 34, 35,
        36]
      if (month.indexOf(+formState.step) === -1) formState.step = '12'
      return month
    } else if (price < 150000) {
      let month = [
        12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23,
        24, 25, 26, 27, 28, 29, 30, 31, 32, 33, 34, 35,
        36, 37, 38, 39, 40, 41, 42, 43, 44, 45, 46, 47,
        48, 49, 50, 51, 52, 53, 54, 55, 56, 57, 58, 59,
        60]
      if (month.indexOf(+formState.step) === -1) formState.step = '12'
      return month
    } else {
      let month = [
        12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23,
        24, 25, 26, 27, 28, 29, 30, 31, 32, 33, 34, 35,
        36, 37, 38, 39, 40, 41, 42, 43, 44, 45, 46, 47,
        48, 49, 50, 51, 52, 53, 54, 55, 56, 57, 58, 59,
        60, 61, 62, 63, 64, 65, 66, 67, 68, 69, 70, 71,
        72, 73, 74, 75, 76, 77, 78, 79, 80, 81, 82, 83,
        84]
      return month
    }
  }

  if ($user.name && ($user.name.indexOf('СамАвто') > -1 || $user.name.indexOf('Авангард') > -1)) {
    let month = [
      12,
      24,
      36,
      48,
      60]
    return month
  }

  if ($user.name && $user.name.indexOf('Шувалово') > -1) {
    if (price <= 30000) {
      let month = [12]
      if (month.indexOf(+formState.step) === -1) formState.step = '12'
      return month
    } else {
      let month = [
        12,
        24,
        36,
        48,
        60,
        72,
        84]
      return month
    }
  }

  if ($user.name && $user.name.indexOf('Гусар') > -1) {
    if (price <= 20000) {
      let month = [12]
      if (month.indexOf(+formState.step) === -1) formState.step = '12'
      return month
    } else {
      let month = [
        12,
        24,
        36,
        48,
        60]
      return month
    }
  }

  if ($user.name && $user.name.indexOf('(Legacy Light)') > -1) {
    return [12]
  }

  formState.step = '12'
  return [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12]
})

const calc_end = () => {
  if (!formState.start || !formState.step) return false
  formState.end = moment(formState.start, 'DD.MM.YYYY')
      .add(formState.step, 'month')
      .format('DD.MM.YYYY')
}
calc_end()

watch(() => formState.step, calc_end)
watch(() => formState.start, calc_end)

const loading = ref(false)
const submit = async () => {

  let price = parseInt(formState.price.replaceAll(' ', ''))

  if (['Avantime'].indexOf($user.name) > -1) {
    if (price < 40000) {
      customError['price'] = 'Не может быть меньше 40.000'
      return false
    }
  } else if (
      $user.name.indexOf('Авангард') > -1 ||
      $user.name.indexOf('СамАвто') > -1
  ) {
    if (price < 20000) {
      customError['price'] = 'Не может быть меньше 20 000'
      return false
    }
    if (price > 500000) {
      customError['price'] = 'Не может быть больше 500 000'
      return false
    }
  } else if ($user.name.indexOf('Шувалово') > -1) {
    if (price < 20000) {
      customError['price'] = 'Не может быть меньше 20 000'
      return false
    }
    if (price > 1000000) {
      customError['price'] = 'Не может быть больше 1 000 000'
      return false
    }
  } else if ($user.name.indexOf('Гусар') > -1) {
    if (price < 3500) {
      customError['price'] = 'Не может быть меньше 3 500'
      return false
    }
    if (price > 500000) {
      customError['price'] = 'Не может быть больше 500 000'
      return false
    }
  } else {
    if (price < 1000) {
      customError['price'] = 'Не может быть меньше 1.000'
      return false
    }
  }

  loading.value = true
  await $axios.post('docs', toRaw(formState)).then(r => {
    router.push({name: 'Archive', params: {s: 'id:' + r.data}})
  }).catch(() => {
  })
  loading.value = false
}
</script>
