import { createRouter, createWebHistory } from 'vue-router'
import Home from '../views/Home.vue'

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home,
    beforeEnter: checkAuth,
    meta: { layout: 'Default' }
  },
  {
    path: '/login',
    name: 'Login',
    component: () => import('../views/Login.vue'),
    meta: { layout: 'Empty' }
  },
  {
    path: '/archive',
    name: 'Archive',
    component: () => import('../views/Arhive.vue'),
    meta: { layout: 'Default' }
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

function checkAuth(to, from, next){
  if(!$cookie.getCookie($auth_name)) next('/login');
  else next()
}

export default router
