<template>
  <component :is="layout">
    <router-view/>
  </component>
</template>

<script>
import Default from "@/layouts/Default";
import Empty from "@/layouts/Empty";

export default {
  components: {Default, Empty},
  computed: {
    layout() {
      return this.$route.meta.layout ? this.$route.meta.layout : 'Empty'
    }
  }
}
</script>

<style lang="less">
@import '~ant-design-vue/dist/antd.less';

</style>
