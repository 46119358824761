import {name, version} from '../package.json'
window.$version = version
window.$auth_name = name

import { createApp } from 'vue'
import App from './App.vue'
import router from './router'

import {VueCookieNext} from "vue-cookie-next";
window.$cookie = VueCookieNext

import axios from 'axios'
axios.defaults.baseURL =
  (process.env.VUE_APP_API_URL ? process.env.VUE_APP_API_URL : 'https://api.legacycard.ru') + '/api/partner/'
axios.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded'
axios.defaults.headers.common['Authorization'] = `Bearer ${$cookie.getCookie($auth_name)}`
window.$axios = axios

import Maska from 'maska'

createApp(App)
  .use(router)
  .use(Maska)
  .mount('#app')
